<template>
	<v-container
		class="fill-height d-flex justify-center align-center background"
		:height="'100%'"
		min-height="'100%'"
		transparent
		fluid>
		<v-btn
			class="theme-switcher"
			text
			icon
			v-if="darkModeIsOn"
			@click="darkModeChange(false)">
			<v-icon color="primary">mdi-sunglasses</v-icon>
		</v-btn>
		<v-btn
			class="theme-switcher"
			depressed
			icon
			v-else
			@click="darkModeChange(true)">
			<v-icon color="primary">mdi-moon-waning-crescent</v-icon>
		</v-btn>
		<v-container class="containter d-flex align-center">
			<v-row
				:height="'100%'"
				:min-height="'100%'">
				<v-col>
					<v-card
						class="v-card--material true pa-3 px-5 py-3 ml-auto mr-auto containter2"
						:max-width="'100%'"
						:width="'400'">
						<card-heading
							color="primary"
							classAdd="containter2">
							<template v-slot:default>
								<div class="display-2 font-weight-bold ml-auto mr-auto">
									Login
								</div>
							</template>
						</card-heading>
						<v-card-text class="mt-5 mb-5 text-center">
							<v-form>
								<v-text-field
									class="mt-8 text-button"
									hint="Email"
									placeholder="Email"
									name="email"
									prepend-icon="mdi-email"
									type="text"
									v-model="email"
									single-line
									outlined
									dense
									@keydown.enter.prevent="onSubmit"></v-text-field>

								<v-text-field
									id="password"
									class="mb-8 text-button"
									hint="PASSWORD"
									placeholder="Password"
									name="password"
									prepend-icon="mdi-lock"
									v-model="password"
									single-line
									outlined
									dense
									@keydown.enter.prevent="onSubmit"
									:append-icon="
										passwordHidden ? 'visibility' : 'visibility_off'
									"
									@click:append="() => (passwordHidden = !passwordHidden)"
									:type="passwordHidden ? 'password' : 'text'"></v-text-field>
							</v-form>

							<v-btn
								class="mx-0"
								color="primary"
								large
								depressed
								@click="onSubmit"
								:disabled="isLoading">
								Let's Go
							</v-btn>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { constants } from "../constants/constants";

export default {
	components: {
		CardHeading: () => import("../components/shared/CardHeading.vue"),
	},
	data() {
		return {
			email: "",
			password: "",
			isLoading: false,
			passwordHidden: true,
		};
	},
	computed: {
		...mapGetters("notification", ["errorMessage", "notifyType"]),
		...mapGetters([
			"getVersion",
			"loginSuccess",
			"notification/notifyType",
			"catUrl",
		]),
		darkModeIsOn() {
			localStorage.setItem(
				constants.settingsLocalStorageKey,
				this.$vuetify.theme.dark
			);
			return this.$vuetify.theme.dark;
		},
	},
	methods: {
		...mapActions("notification", ["setMessage"]),
		...mapActions(["login"]),
		async onSubmit() {
			// window.location.href = this.catUrl + "/api/v1/auth/login";
			this.isLoading = true;

			await this.login({
				email: this.email,
				password: this.password,
			});
			this.isLoading = false;
			if (this.loginSuccess) {
				this.$router.push({
					name: "home",
					params: { breadcrumbs: { title: "Dashboard", hard: true } },
				});
			}
		},
		async darkModeChange(val) {
			if (val != undefined) {
				this.$vuetify.theme.dark = val;
				return;
			}
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
			await this.$nextTick();
			document.querySelector(".theme-switcher").blur();
		},
	},
	mounted() {
		console.info(
			"%c Kajot Games :%c LOGAN version %c: " + this.getVersion + " %c",
			"background-color:#0C244E; color:#35A5DA; padding:3px; border-radius:3px 0 0 3px;",
			"background-color:#005699; color:#47c1f1; padding:3px; border-radius:3px 3px 3px 3px;",
			"background-color:#0C244E; color:#47c1f1; padding:3px; border-radius:0 3px 3px 0;",
			"background:transparent"
		);
	},
	created() {
		if (this.errorMessage != "") this.setMessage({ message: "", type: "" });
	},
};
</script>

<style>
.loginFooter {
	background-color: transparent !important;
	color: var(--v-kajot-nav-text-base);
}
</style>

<style scoped>
.background {
	background: url("../assets/bg.jpg") no-repeat !important;
	background: inherit no-repeat !important;

	background-color: var(--v-primary-base) !important;
	background-blend-mode: multiply !important;
	background-size: cover !important;
}
.containter2 {
	background-color: rgba(255, 255, 255, 0.25) !important;
}

.theme--dark .containter2 {
	background-color: rgba(0, 0, 0, 0.25) !important;
}

.containter2::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0 0 2000px 100px rgba(255, 255, 255, 0.5);
}
.theme--dark .containter2::before {
	box-shadow: inset 0 0 2000px 100px rgba(0, 0, 0, 0.7);
}

.containter {
	min-width: 100dvw;
	height: 100dvh;
	backdrop-filter: blur(0.75dvw) brightness(85%);
}

.containter::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0px 0px 100dvw 5dvw rgba(0, 0, 0, 0.5);
}

.theme-switcher {
	position: absolute;
	top: 1rem;
	right: 1rem;
	z-index: 69;
	background-color: transparent !important;
}
</style>

<style lang="sass" scoped>
.v-main.fill-height
  background: rgba(0,0,0,0.0) !important
</style>
