// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.background[data-v-fafbb59c] {\n\tbackground: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat !important;\n\tbackground: inherit no-repeat !important;\n\n\tbackground-color: var(--v-primary-base) !important;\n\tbackground-blend-mode: multiply !important;\n\tbackground-size: cover !important;\n}\n.containter2[data-v-fafbb59c] {\n\tbackground-color: rgba(255, 255, 255, 0.25) !important;\n}\n.theme--dark .containter2[data-v-fafbb59c] {\n\tbackground-color: rgba(0, 0, 0, 0.25) !important;\n}\n.containter2[data-v-fafbb59c]::before {\n\tcontent: \"\";\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\tbox-shadow: inset 0 0 2000px 100px rgba(255, 255, 255, 0.5);\n}\n.theme--dark .containter2[data-v-fafbb59c]::before {\n\tbox-shadow: inset 0 0 2000px 100px rgba(0, 0, 0, 0.7);\n}\n.containter[data-v-fafbb59c] {\n\tmin-width: 100dvw;\n\theight: 100dvh;\n\t-webkit-backdrop-filter: blur(0.75dvw) brightness(85%);\n\t        backdrop-filter: blur(0.75dvw) brightness(85%);\n}\n.containter[data-v-fafbb59c]::before {\n\tcontent: \"\";\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\tbox-shadow: inset 0px 0px 100dvw 5dvw rgba(0, 0, 0, 0.5);\n}\n.theme-switcher[data-v-fafbb59c] {\n\tposition: absolute;\n\ttop: 1rem;\n\tright: 1rem;\n\tz-index: 69;\n\tbackground-color: transparent !important;\n}\n", ""]);
// Exports
module.exports = exports;
